import { useCallback, useEffect, useRef, useState } from "react";
import StudentClassContext from "src/context/studentClassContext";
import useNav from "src/hooks/useNav";
import {
  classLevels,
  classScheduleAll,
  fetchMemberClassesData,
  regularClasses,
  tutionFees,
} from "src/services/privateApi";
import createArrayFromSize from "src/utils/creatArrayFromSize";
import deepCopy from "src/utils/deepCopy";
import { dateFormat } from "src/utils/formatdate";
import generateClassFormListInfo from "src/utils/generateClassFormListInfo";
import messages from "src/utils/messages";
import subtractMonths from "src/utils/subtractMonths";
import { swalError } from "src/utils/swalHelpers";
import { v4 as uuidv4 } from "uuid";

const StudentClassProvider = ({ children, ...props }) => {
  const [regularClassList, setRegularClassList] = useState([]);
  const [tuitonFeesList, setTuitionFessList] = useState([]);
  const [regularClassScheduleList, setRegularClassScheduleList] = useState([]);
  const [classLevelList, setClassLeveList] = useState([]);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);

  const { currentMonth } = useNav();

  const tableFilterButtonRef = useRef(null);

  const { newFormList, newSelectedClassId, newBillingAmount, newDate } =
    generateClassFormListInfo();

  const [formList, setFormList] = useState(newFormList);
  const [selectedClassId, setSelectedClassId] = useState(newSelectedClassId);
  const [billingAmount, setBillingAmount] = useState(newBillingAmount);
  // note praveen keep this in sync with other states!
  const [date, setDate] = useState(currentMonth);

  useEffect(() => {
    if (currentMonth) {
      setDate(currentMonth);
    }
  }, [currentMonth]);

  const getTotalFees = useCallback(
    newFormList => {
      return newFormList.reduce((acc, curr) => {
        const { closed_flag, times, regular_class_id } = curr;

        const tuition = tuitonFeesList.find(
          fee =>
            fee.regular_class_id === regular_class_id && fee.times === times
        );

        acc += tuition
          ? closed_flag
            ? tuition.closure_fee
            : tuition.tution_fee
          : 0;

        return acc;
      }, 0);
    },
    [tuitonFeesList]
  );

  const totalFees = getTotalFees(formList);

  const updateState = useCallback(
    ({ newFormList, newSelectedClassId, newBillingAmount, newDate }) => {
      setFormList(newFormList);
      setSelectedClassId(newSelectedClassId);
      setBillingAmount(newBillingAmount);
      setDate(newDate);
    },
    []
  );

  const updateFormList = useCallback(
    (formData, type = "update") => {
      let newFormList;

      const newFormData = { id: uuidv4() }; // used by add or if new formlist is empty

      if (type === "update") {
        newFormList = formList.map(val =>
          val.id === formData.id ? formData : val
        );
      } else if (type === "delete") {
        newFormList = formList.filter(val => val.id !== formData.id);

        if (newFormList.length === 0) {
          newFormList = [newFormData];
        }

        setSelectedClassId(newFormList[0].id);
      } else if (type === "add") {
        newFormList = [...formList, newFormData];
        setSelectedClassId(newFormData.id);
      }

      setFormList(newFormList);
      setBillingAmount(getTotalFees(newFormList));
    },
    [formList, getTotalFees]
  );

  useEffect(() => {
    if (currentMonth) {
      setDate(currentMonth);
    }
  }, [currentMonth]);

  const disableForm = props.readOnly || initialDataLoaded;
  const maxAllowedClasses = 3;

  const rankClassIdList = classLevelList
    .map(val => {
      return val.regular_class_id;
    })
    .filter((val, index, arr) => {
      return index === arr.indexOf(val);
    });

  const getClassSchedule = regular_class_id => {
    let classSchedule = regularClassScheduleList.find(
      val => parseInt(val.regular_class_id) === parseInt(regular_class_id)
    )?.classSchedule;

    if (!classSchedule) return null;

    classSchedule = classSchedule
      .filter(val => val.classes.length)
      .map(({ day, classes }) => {
        return {
          day,
          classes: [
            ...classes
              .filter(ele => ele.start_time)
              .map(ele => {
                const classLevelInfo = classLevelList.find(
                  ({ id }) => id === ele.max_student
                );
                const timeOptionString = classLevelInfo
                  ? `${ele.start_time} (${classLevelInfo?.in_text})`
                  : `${ele.start_time} (--)`;

                return { ...ele, day, timeOptionString, classLevelInfo };
              }),
          ],
        };
      });

    return classSchedule;
  };

  let formData = deepCopy(formList.find(val => val.id === selectedClassId));

  const classSchedule = getClassSchedule(formData.regular_class_id);

  const dayTimeOptionList = pos => {
    const dayWeek = formData[`day_week_${pos}`];

    const options = [{ value: "no-value", label: "時間を選択してください" }];

    if (classSchedule && dayWeek) {
      const weekDaySchedule = classSchedule.find(val => val.day === dayWeek);

      weekDaySchedule.classes.forEach(val => {
        const from = val?.classLevelInfo?.from_level;
        const to = val?.classLevelInfo?.to_level;
        if (typeof to === "number" && typeof from === "number") {
          if (formData.class && formData.class >= from && formData.class <= to)
            options.push({
              ...val,
              value: val.id,
              label: val.timeOptionString,
            });
        } else {
          options.push({ ...val, value: val.id, label: val.timeOptionString });
        }
      });
    }

    return options;
  };

  createArrayFromSize(5).forEach(pos => {
    const start_time = formData[`start_time_${pos}`];
    const end_time = formData[`end_time_${pos}`];
    const class_level = formData[`class_level_${pos}`];

    // Skip processing if the required conditions are not met
    if (!start_time || !end_time || class_level) return;

    const timeOptionList = dayTimeOptionList(pos);

    // Check if there are multiple time options and find the matching ID
    if (timeOptionList.length > 1) {
      const matchingOption = timeOptionList.find(option => {
        return option.start_time === start_time && option.end_time === end_time;
      });

      if (matchingOption?.id) {
        formData[`class_level_${pos}`] = matchingOption.id;
      }
    }
  });

  const isRankable = () => {
    return rankClassIdList.includes(parseInt(formData.regular_class_id));
  };

  const isDayTimeRequired = () => {
    const classData = regularClassList.find(val => {
      return parseInt(val.id) === parseInt(formData.regular_class_id);
    });

    return classData?.day_selection_flag === 1 ? true : false;
  };

  const validateFormData = async () => {
    const validationRules = [
      {
        key: "regular_class_id",
        test: () => !!formData.regular_class_id,
        message: "所属クラスを選択してください",
      },
      {
        key: "times",
        test: () => !!formData.times,
        message: "回数を選択してください",
      },
      {
        key: "days",
        test: () => {
          return (
            !isDayTimeRequired() ||
            createArrayFromSize(formData.times).every(pos => {
              const key = `day_week_${pos}`;
              return !!formData[key];
            })
          );
        },
        message: "曜日を選択してください",
      },
      {
        key: "dayTime",
        test: () => {
          return (
            !isDayTimeRequired() ||
            createArrayFromSize(formData.times).every(pos => {
              const key = `class_level_${pos}`;
              return !!formData[key];
            })
          );
        },
        message: "時間を選択してください",
      },
      {
        key: "billingAmount",
        test: () => billingAmount !== "",
        message: "請求額を入力してくださいください",
      },
    ];

    for (const rule of validationRules) {
      const isValid = rule.test(); // Call the test function
      if (!isValid) {
        await swalError(rule.message); // Show swal error
        return false; // Stop validation and return false
      }
    }
    return true;
  };

  const [classList, setClassList] = useState([]);

  const getStartDate = currentMonth => {
    if (!currentMonth) return null;
    let date = subtractMonths(currentMonth, 6);
    return dateFormat(date, 3);
  };

  const formikRef = useRef();

  const fetchMemberClassesDataWrapper = useCallback(
    async (memberId, calledFrom) => {
      const { start_date, end_date } = formikRef.current.values;

      const callBacks = {
        effect: (resType, data) => setClassList(resType === 1 ? data.data : []),
        handler: (resType, data) => {
          setClassList(resType === 1 ? data.data : []);
          const alertMessages = {
            2: messages.classDataNotFoundDate,
            3: data.message,
          };
          swalError(alertMessages[resType]);
        },
      };

      await fetchMemberClassesData(
        memberId,
        start_date,
        end_date,
        callBacks[calledFrom]
      );
    },
    []
  );

  const contextValues = {
    regularClassList,
    tuitonFeesList,
    regularClassScheduleList,
    classLevelList,
    currentMonth,
    initialDataLoaded,
    updateState,
    billingAmount,
    setBillingAmount,
    selectedClassId,
    setSelectedClassId,
    date,
    tableFilterButtonRef,
    formList,
    updateFormList,
    disableForm,
    maxAllowedClasses,
    rankClassIdList,
    formData,
    isRankable,
    validateFormData,
    classList,
    getStartDate,
    fetchMemberClassesDataWrapper,
    formikRef,
    totalFees,
    classSchedule,
    dayTimeOptionList,
    ...props,
  };

  useEffect(() => {
    const fetchData = async () => {
      setInitialDataLoaded(true);

      try {
        // Fetch regular classes
        const regularClassesResponse = await regularClasses();
        if (regularClassesResponse.resType === 1) {
          const regularClassData = regularClassesResponse.data.data;
          setRegularClassList(regularClassData);
        } else if (regularClassesResponse.resType === 2) {
          await swalError(messages.regularClassDataNotFound);
        } else if (regularClassesResponse.resType === 3) {
          await swalError(regularClassesResponse.data.message);
        }

        const classScheduleResponse = await classScheduleAll();

        if (classScheduleResponse.resType === 1) {
          setRegularClassScheduleList(classScheduleResponse.data.data);
        } else if (classScheduleResponse.resType === 2) {
          await swalError(messages.classScheduleNotFound);
        } else if (classScheduleResponse.resType === 3) {
          await swalError(classScheduleResponse.data.message);
        }

        // Fetch tuition fees
        const tuitionFeesResponse = await tutionFees();
        if (tuitionFeesResponse.resType === 1) {
          setTuitionFessList(tuitionFeesResponse.data.data);
        } else if (tuitionFeesResponse.resType === 2) {
          await swalError(messages.tuitionInfoNotFound);
        } else if (tuitionFeesResponse.resType === 3) {
          await swalError(tuitionFeesResponse.data.message);
        }

        // Fetch class levels
        const classLevelsResponse = await classLevels();
        if (classLevelsResponse.resType === 1) {
          setClassLeveList(classLevelsResponse.data.data);
        } else if (classLevelsResponse.resType === 3) {
          await swalError(classLevelsResponse.data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        await swalError(messages.contactMessage);
      } finally {
        setInitialDataLoaded(false);
      }
    };

    fetchData();
  }, []);

  return (
    <StudentClassContext.Provider value={contextValues}>
      {children}
    </StudentClassContext.Provider>
  );
};

export default StudentClassProvider;
