import { Formik, useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import MyInput from "src/components/common/FormElements/MyInput";
import { useEffect } from "react";
import castZero from "src/utils/castZero";
import TextLabel from "src/components/extrapage/Page19/TextLabel.js";
import billingFormValidationSchema from "src/components/extrapage/Page19/billingFormValidationSchema";
import BillingFormNumberInput from "./BillingFormNumberInput";

const BillingForm = ({ depositData, depositUpdate, date, readOnly }) => {
  const formFields = [
    "id",
    "billing_amount",
    "transfer_invoice_amount",
    "transfer_amount",
    "cash_deposit_amount",
    "cash_deposit_date",
    "cash_deposit_amount2",
    "cash_deposit_date2",
    "excess_deficiency",
    "cumulative_excess_deficiency",
    "note",
  ];

  return (
    <div className="row m-0 my-1 p-1 billing-form">
      <div className="border border-secondary border-1">
        <div className="row m-0 mt-2 justify-content-between">
          <div className="col-md-6">
            <div className="row m-0 g-1 align-items-center">
              <div className="col-md-2 pt-1">処理年月:</div>
              <div className="col-md-4">
                <Formik initialValues={{ date }} enableReinitialize={true}>
                  {formik => (
                    <Form>
                      <MyInput
                        label=""
                        name="date"
                        type="month"
                        max="9999-12"
                        placeholder=""
                        disabled
                      />
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        {/* as formik wont reinitialized, i used a key to reset the state for this tree */}
        <div key={depositData.id}>
          <Formik
            initialValues={depositData}
            validationSchema={!readOnly && billingFormValidationSchema}
            enableReinitialize={true}
            onSubmit={async (formValues, formikHelper) => {
              let values = {};

              formFields.forEach(field => (values[field] = formValues[field]));

              let {
                billing_amount,
                transfer_amount,
                cash_deposit_amount,
                cash_deposit_amount2,
              } = values;

              const excess_deficiency =
                castZero(transfer_amount) +
                castZero(cash_deposit_amount) +
                castZero(cash_deposit_amount2) -
                castZero(billing_amount);

              depositUpdate({ ...values, excess_deficiency });
            }}
          >
            {formik => (
              <Form onSubmit={formik.handleSubmit}>
                <div>
                  <div className="row m-0 mt-2 g-1 align-items-center">
                    <div className="col-1 me-2 btn inputs_bg border d-flex justify-content-center align-items-center mw-100">
                      請求額
                    </div>
                    <div className="col-md-4 border">
                      <div className="col text-center border-bottom">振替</div>
                      <div className="row m-0 text-center">
                        <div className="col border-end mw-100">請求額</div>
                        <div className="col border-end mw-100">入金額</div>
                        <div className="col mw-100">入金日</div>
                      </div>
                    </div>
                    <div className="col border ms-2">
                      <div className="col text-center border-bottom">現金</div>
                      <div className="row m-0 text-center">
                        <div className="col border-end">入金額</div>
                        <div className="col border-end">入金日</div>
                        <div className="col border-end">入金額(2)</div>
                        <div className="col ">入金日(2)</div>
                      </div>
                    </div>
                    <div className="col-2 ms-2">
                      <div className="col ms-2">
                        <div className="row m-0 text-center">
                          <div className="col-6">
                            <TextLabel label="入金合計" />
                          </div>
                          <div className="col-6">
                            <TextLabel label="過不足累計" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row m-0 mt-2 g-1">
                    <div className="col-md-1 me-2">
                      <BillingFormNumberInput
                        label=""
                        name="billing_amount"
                        type="number"
                        placeholder=""
                        selectTextOnFocus={true}
                        readOnly={readOnly}
                      />
                    </div>
                    <div className="col-md-4">
                      <div className="row m-0 text-center">
                        <div className="col-4">
                          <BillingFormNumberInput
                            label=""
                            name="transfer_invoice_amount"
                            type="number"
                            placeholder=""
                            disabled
                            readOnly={true}
                          />
                        </div>
                        <div className="col-4">
                          <BillingFormNumberInput
                            label=""
                            name="transfer_amount"
                            type="number"
                            placeholder=""
                            disabled
                            readOnly={readOnly}
                          />
                        </div>
                        <div className="col-4">
                          <MyInput
                            label=""
                            name="transfer_deposit_date"
                            type="date"
                            max="9999-12-31"
                            placeholder=""
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col ms-2">
                      <div className="row m-0">
                        <div className="col-3">
                          <BillingFormNumberInput
                            label=""
                            name="cash_deposit_amount"
                            type="number"
                            placeholder=""
                            selectTextOnFocus={true}
                            readOnly={readOnly}
                          />
                        </div>
                        <div className="col-3">
                          <MyInput
                            label=""
                            name="cash_deposit_date"
                            type="date"
                            max="9999-12-31"
                            placeholder=""
                            readOnly={readOnly}
                          />
                        </div>
                        <div className="col-3">
                          <BillingFormNumberInput
                            label=""
                            name="cash_deposit_amount2"
                            type="number"
                            placeholder=""
                            selectTextOnFocus={true}
                            readOnly={readOnly}
                          />
                        </div>
                        <div className="col-3">
                          <MyInput
                            label=""
                            name="cash_deposit_date2"
                            type="date"
                            max="9999-12-31"
                            placeholder=""
                            readOnly={readOnly}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-2 ms-2">
                      <div className="col ms-2">
                        <div className="row m-0 text-center">
                          <div className="col-6">
                            <DepositTotalInput
                              label=""
                              name="cash_deposit_total"
                              type="number"
                              placeholder=""
                              disabled
                              readOnly={true}
                            />
                          </div>
                          <div className="col-6">
                            {/*note praveen specific component, changing
                             the parent component might affect this component
                          */}
                            <CumilativeExcessInput
                              label=""
                              name="cumulative_excess_deficiency"
                              type="number"
                              placeholder=""
                              disabled
                              readOnly={true}
                              depositData={depositData}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row m-0 mt-2 mb-2 g-1">
                    <div className="col-1">
                      <TextLabel label="備考" />
                    </div>
                    <div className="col-10">
                      <MyInput
                        label=""
                        name="note"
                        type="text"
                        placeholder=""
                        selectTextOnFocus={true}
                        readOnly={readOnly}
                      />
                    </div>
                    <div className="col-1">
                      <button
                        className="btn btn-primary w-100"
                        type="submit"
                        disabled={readOnly || !formik.isValid}
                      >
                        保存
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

const DepositTotalInput = props => {
  const {
    values: { transfer_amount, cash_deposit_amount, cash_deposit_amount2 },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    const value =
      castZero(transfer_amount) +
      castZero(cash_deposit_amount) +
      castZero(cash_deposit_amount2);

    setFieldValue(props.name, value);
  }, [
    transfer_amount,
    cash_deposit_amount,
    cash_deposit_amount2,
    props.name,
    setFieldValue,
  ]);

  return <BillingFormNumberInput {...props} />;
};

const CumilativeExcessInput = ({ depositData, ...props }) => {
  const {
    values: {
      cash_deposit_total,
      billing_amount,
      transfer_amount,
      cash_deposit_amount,
      cash_deposit_amount2,
    },
    setFieldValue,
  } = useFormikContext();

  useEffect(() => {
    const excess_deficiency =
      castZero(transfer_amount) +
      castZero(cash_deposit_amount) +
      castZero(cash_deposit_amount2) -
      castZero(billing_amount);

    const carryOverExcessDeficiency =
      depositData.cumulative_excess_deficiency - depositData.excess_deficiency;

    const value =
      castZero(carryOverExcessDeficiency) + castZero(excess_deficiency);

    setFieldValue(props.name, value);
  }, [
    cash_deposit_total,
    billing_amount,
    transfer_amount,
    cash_deposit_amount,
    cash_deposit_amount2,
    props.name,
    setFieldValue,
    depositData.cumulative_excess_deficiency,
    depositData.excess_deficiency,
  ]);

  return <BillingFormNumberInput {...props} />;
};

export default BillingForm;
