import "react-resizable/css/styles.css";
import { useCallback, useEffect, useState } from "react";
import useNav from "src/hooks/useNav";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import MyInput from "src/components/common/FormElements/MyInput";
import _, { update } from "lodash";
import createArrayFromSize from "src/utils/creatArrayFromSize";
import StudentClassForm from "./StudentClassForm";
import useStudentClass from "src/hooks/useStudentClass";
import { fetchMemberClassesData } from "src/services/privateApi";
import { swalError, swalWarning } from "src/utils/swalHelpers";
import messages from "src/utils/messages";
import generateClassFormListInfo from "src/utils/generateClassFormListInfo";

const StudentClassBody = () => {
  const { currentMonth } = useNav();
  const [hasLoaded, setHasLoaded] = useState(false);
  const { updateState, memberData, readOnly, date } = useStudentClass();

  const fetchMemberClassesDataWrapper = useCallback(
    async (memberId, date, calledFrom, searchType) => {
      const callBacks = {
        effect: (resType, data) => {
          if (resType === 1) {
            const newFormListInfo = generateClassFormListInfo(data.data);
            updateState(newFormListInfo);
          }
        },
        handler: async (resType, data) => {
          if (resType === 1) {
            const newFormListInfo = generateClassFormListInfo(data.data);
            updateState(newFormListInfo);
          } else if (resType === 2) {
            const alertMessages = {
              read: messages.classDataNotFoundDateRead,
              add: messages.addNewClassData,
            };
            await swalError(alertMessages[searchType]);
            const newFormListInfo = generateClassFormListInfo(null, null, date);
            updateState(newFormListInfo);
          } else if (resType === 3) {
            await swalError(data.message); // messages.contactMessage
          }
        },
      };

      await fetchMemberClassesData(memberId, date, date, callBacks[calledFrom]);
    },
    [updateState]
  );

  useEffect(() => {
    if (memberData.id && currentMonth) {
      fetchMemberClassesDataWrapper(
        memberData.id,
        currentMonth,
        "effect"
      ).finally(() => setHasLoaded(true));
    }
  }, [currentMonth, memberData.id, fetchMemberClassesDataWrapper]);

  return (
    <div className="row m-0 ">
      <div className="card pb-1 pt-2">
        <div className="row m-0 mt-2 my-2">
          <Formik
            initialValues={{ date }}
            enableReinitialize={true}
            onSubmit={async ({ date }, { setSubmitting }) => {
              setSubmitting(true);
              const searchType = !readOnly ? "add" : "read";

              await fetchMemberClassesDataWrapper(
                memberData.id,
                date,
                "handler",
                searchType
              );

              setSubmitting(false);
            }}
          >
            {formik => {
              const buttonProps = !readOnly
                ? { children: "検索/追加" }
                : { children: "検索" };

              const disabled =
                _.isEmpty(memberData) ||
                !currentMonth ||
                formik.isSubmitting ||
                !hasLoaded;

              return (
                <div className="row col-md-5">
                  <Form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-3 text-md-end pt-1">年月:</div>
                      <div className="col-4">
                        <MyInput
                          label=""
                          name="date"
                          type="month"
                          max="9999-12"
                          placeholder=""
                          disabled={disabled}
                        />
                      </div>
                      <div className="col-md-3">
                        <button
                          className="btn btn-primary w-100"
                          type="submit"
                          disabled={disabled}
                          {...buttonProps}
                        ></button>
                      </div>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        </div>
        <StudentClassForm />
      </div>
    </div>
  );
};

export default StudentClassBody;
