import { Formik } from "formik";
import { Form } from "react-bootstrap";
import MyInput from "src/components/common/FormElements/MyInput";

const MonthLabel = ({ date }) => {
  return (
    <Formik initialValues={{ date }} enableReinitialize={true}>
      {formik => (
        <Form>
          <MyInput
            label=""
            name="date"
            type="month"
            max="9999-12"
            placeholder=""
            disabled
          />
        </Form>
      )}
    </Formik>
  );
};

export default MonthLabel;
