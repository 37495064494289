import React from "react";
import { useHistory } from "react-router-dom"; // Assuming you're using React Router

const BackButton = ({ position, redirectTo }) => {
  const history = useHistory();

  const goBack = () => {
    if (redirectTo) {
      history.push(redirectTo); // Redirect to the specified page
    } else {
      history.goBack(); // Go back in history if redirectTo is not provided
    }
  };

  const buttonStyles = {
    position: position?.position || "absolute",
    bottom: position?.bottom || "0",
    right: position?.right || "0",
    margin: "0.5rem",
  };

  return (
    <button
      className="btn btn-sm btn-primary ms-2"
      variant="primary"
      style={buttonStyles}
      onClick={goBack}
    >
      戻る
    </button>
  );
};

// note praveen create a new component?
export const RedirectButton = ({ redirectTo, children, ...props }) => {
  const history = useHistory();

  const handleClick = () => {
    if (redirectTo) {
      history.push(redirectTo);
    } else {
      history.goBack();
    }
  };

  return (
    <button onClick={handleClick} {...props}>
      {children}
    </button>
  );
};

export default BackButton;
