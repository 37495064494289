import { Table as AntTable, ConfigProvider } from "antd";
import "react-resizable/css/styles.css";
import antdTheme from "src/themes/antdTheme";
import BillingTableCSVDownload from "src/components/extrapage/Page19/BillingTableCSVDownload";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import useNav from "src/hooks/useNav";
import subtractMonths from "src/utils/subtractMonths";
import { dateFormat } from "src/utils/formatdate";
import MyInput from "src/components/common/FormElements/MyInput";
import { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import billingTableColums from "src/components/extrapage/Page19/billingTableColums";
import { searchMemberDeposit } from "src/services/privateApi";
import swal from "sweetalert";

// note praveen add date range validation in the future
const validationSchema = Yup.object({
  start_date: Yup.date().nullable(),
  end_date: Yup.date().nullable(),
});

const BillingTable = ({
  studentInfo,
  updateState,
  tableFilterButtonRef,
  depositList,
  updateDepositList,
  selectedRowId,
}) => {
  const { currentMonth } = useNav();

  const startDate = () => {
    if (!currentMonth) return null;

    let date = subtractMonths(currentMonth, 6);

    return dateFormat(date, 3);
  };

  const memberDepositSearch = useCallback(
    async payload => {
      try {
        const { data, err } = await searchMemberDeposit(
          payload,
          studentInfo.id
        );

        if (err) {
          throw new Error(data.message);
        }

        updateDepositList(data.data);
      } catch (err) {
        console.log(err);
      }
    },
    [studentInfo.id, updateDepositList]
  );

  useEffect(() => {
    if (currentMonth && studentInfo.id) {
      tableFilterButtonRef.current.click();
    }
  }, [currentMonth, studentInfo.id, tableFilterButtonRef]);

  const handleRowClick = record => {
    updateState(record);
  };

  const rowClassName = record =>
    selectedRowId === record.id ? "table-selected-row" : "";

  return (
    <div className="card mt-3">
      <div className="row m-0 card-header">
        <Formik
          initialValues={{ start_date: startDate(), end_date: null }}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={async (values, { setSubmitting }) => {
            memberDepositSearch(values);
          }}
        >
          {formik => {
            return (
              <Form onSubmit={formik.handleSubmit}>
                <div className="col-md-8">
                  <div className="row m-0 align-items-center">
                    <div
                      className="col"
                      style={{ width: "12%", flex: "0 0 auto" }}
                    >
                      処理年月:{" "}
                    </div>
                    <div className="col">
                      <MyInput
                        label=""
                        name="start_date"
                        type="month"
                        max="9999-12"
                        placeholder=""
                      ></MyInput>
                    </div>
                    <div
                      className="col"
                      style={{ width: "5%", flex: "0 0 auto" }}
                    >
                      <div className="text-center py-2">~</div>
                    </div>
                    <div className="col">
                      <MyInput
                        label=""
                        name="end_date"
                        type="month"
                        max="9999-12"
                        placeholder=""
                      ></MyInput>
                    </div>
                    <div
                      className="col"
                      style={{ width: "14%", flex: "0 0 auto" }}
                    >
                      <button
                        style={{ width: "100%" }}
                        className="btn btn-primary"
                        type="submit"
                        disabled={!formik.isValid}
                        ref={tableFilterButtonRef}
                      >
                        絞り込み
                      </button>
                    </div>
                    <div className="col">
                      <BillingTableCSVDownload
                        {...{ studentInfo, depositList }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>

      <div className="table-responsive p-3">
        <ConfigProvider theme={antdTheme}>
          <AntTable
            bordered
            dataSource={depositList}
            columns={billingTableColums()}
            rowClassName={rowClassName}
            onRow={(record, rowIndex) => ({
              onClick: e => {
                handleRowClick(record);
              },
            })}
            className="text-left"
            pagination={false}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default BillingTable;
