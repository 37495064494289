import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import privateApi from "../../../services/privateApi";
import ja from "date-fns/locale/ja";
import InputMask from "react-input-mask";
import { getCurrentMonth } from "../../../services/auth/token";
import moment from "moment";
import { Button, Form } from "react-bootstrap";

const seasons = {
  1: "春",
  2: "夏",
  3: "冬",
  4: "秋"
};

const ShortTermAdvanceSearch = ({ advanceSearch, setAdvanceSearch }) => {
  const [buses, setBuses] = useState([]);
  const [allClasses, setAllClasses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [dataInput, setDataInput] = useState(advanceSearch);
  const [classes, setClasses] = useState([]);

  const getGenerelRes = async e => {
    const month = getCurrentMonth();
    const response = await privateApi.get(
      `admin/general-resources?type=bus,bank,member_code&processed_date=${moment(month).format("YYYY-MM")}`
    );
    if (response.status === 200) {
      setBuses(response.data.bus);
    } else {
      console.log("Server Error");
    }
  };
  const getClasses = async (year, season) => {
    let response;
    if (!year) {
      response = await privateApi.get(
        `admin/short-term-classes?per_page=1000`);
    } else {
      response = await privateApi.get(
        `admin/short-term-classes?opening_year=${year}&?opening_season=${season}&?per_page=1000`);
    }
    if (response.status === 200) {
      if (!year && !season) {
        setAllClasses(response.data.data);
      }
      setClasses(response.data.data);
    } else {
      console.log("サーバーの不具合");
    }
  };

  const getCourses = async short_term_class_id => {
    try {
      const response = await privateApi.get(
        `admin/get-short-term-courses/${short_term_class_id}`
      );
      if (response && response.status === 200) {
        setCourses(response?.data?.data?.short_term_courses);
      } else {
        console.log("サーバーの不具合");
      }
    } catch (error) {
      console.error("An error occurred while fetching courses:", error);
    }
  };
  const changeDataInput = (change) => {
    if (change.opening_year) {
      getClasses(change.opening_year);
      setDataInput({
        opening_season: "",
        opening_year: change.opening_year,
        short_term_class_id: "",
        class_name: "",
        short_term_course_id: "",
        course_name: "",
        bus_flag: false,
      });
    }
    if (change.opening_season) {
      getClasses(dataInput.opening_year, change.opening_season);
      setDataInput(prev => ({
        opening_year: prev.opening_year, ...change, short_term_class_id: "",
        class_name: "",
        short_term_course_id: "",
        course_name: "",
      }));
    }
    if (change.short_term_class_id) {
      const selectedClass = classes.find((c) => c.id === parseInt(change.short_term_class_id));
      if (selectedClass) {
        setDataInput({
          ...dataInput,
          short_term_class_id: selectedClass.id,
          opening_year: selectedClass.opening_year,
          class_code: selectedClass.class_code,
          class_name: selectedClass.class_name,
          opening_season: selectedClass.opening_season,
          short_term_course_id: ""
        });
        getCourses(change.short_term_class_id);
      }
    };
    if (change.short_term_course_id) {
      setDataInput(prev => ({ ...prev, ...change, course_name: courses.find(c => c.id == change.short_term_course_id).course_name }));
    }
  };


  useEffect(() => {
    getClasses();
    getGenerelRes();
  }, []);
  useEffect(() => {
    setAdvanceSearch(p => ({ ...p, ...dataInput }));
  }, [dataInput]);

  const handleInput = e => {
    e.persist();
    setAdvanceSearch({ ...advanceSearch, [e.target.name]: e.target.value });
  };

  const handleCheckBox = e => {
    setAdvanceSearch({ ...advanceSearch, [e.target.name]: e.target.checked });
  };
  const handleClear = () => {
    setAdvanceSearch({
      name: "",
      furigana_name: "",
      member_code_start: "",
      member_code_end: "",
      gender: "",
      birthday_start: "",
      birthday_end: "",
      telePhone_1: "",
      telePhone_2: "",
      bus_user: "",
      zip_code: ""
    });
    setDataInput({
      opening_season: "",
      opening_year: "",
      short_term_class_id: "",
      class_name: "",
      short_term_course_id: "",
      course_name: "",
      bus_flag: false,
      bus_course_number: ""
    });
  };
  return (
    <div className="row">
      <div className="form-group mb-2 row">
        <label className="col-sm-3 col-form-label text-sm-end">会員番号:</label>
        <div className="col-sm-2">
          <input
            className="form-control"
            type="text"
            defaultValue={advanceSearch.member_code_start}
            onChange={handleInput}
            name="member_code_start"
          />
        </div>
        ~
        <div className="col-sm-2">
          <input
            className="form-control"
            type="text"
            defaultValue={advanceSearch.member_code_end}
            onChange={handleInput}
            name="member_code_end"
          />
        </div>
        <div className="col-sm-4">
          <Button
            className="btn"
            onClick={handleClear}
            variant="warning"
          >
            検索条件クリア
          </Button>
        </div>
      </div>
      <div className="form-group mb-2 row">
        <label className="col-sm-3 col-form-label text-sm-end">名前:</label>
        <div className="col-sm-3">
          <input
            className="form-control"
            type="text"
            name="name"
            value={advanceSearch.name}
            onChange={handleInput}
            required
          />
        </div>
        <label className="col-sm-1 col-form-label ">フリガナ:</label>
        <div className="col-sm-3">
          <input
            className="form-control"
            type="text"
            name="furigana_name"
            value={advanceSearch.furigana_name}
            onChange={handleInput}
            required
          />
        </div>
      </div>

      <div className="form-group mb-2 row">
        <label className="col-sm-3 col-form-label text-sm-end">性別:</label>
        <div className="col-sm-9 pt-2">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              value="all"
              onChange={handleInput}
              checked={advanceSearch.gender === "all"}
            />
            <label className="form-check-label" htmlFor="inlineRadio1">
              すべて
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              value="男"
              onChange={handleInput}
              checked={advanceSearch.gender === "男"}
            />
            <label className="form-check-label" htmlFor="inlineRadio2">
              男
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              value="女"
              onChange={handleInput}
              checked={advanceSearch.gender === "女"}
            />
            <label className="form-check-label" htmlFor="inlineRadio1">
              女
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              value="未"
              onChange={handleInput}
              checked={advanceSearch?.gender === "未"}
            />
            <label className="form-check-label mt-1" htmlFor="inlineRadio1">
              未
            </label>
          </div>
        </div>
      </div>

      <div className="form-group mb-2 row">
        <label className="col-sm-3 col-form-label text-sm-end">生年月日:</label>
        <div className="col-sm-3">
          <input
            type="date"
            className="form-control form-control-sm"
            placeholderText="___/__/__"
            value={
              advanceSearch.birthday_start
                ? new Date(advanceSearch.birthday_start)
                  .toISOString()
                  .split("T")[0]
                : ""
            }
            onChange={e => {
              const selectedDate = e.target.value;
              setAdvanceSearch({
                ...advanceSearch,
                birthday_start: selectedDate,
              });
            }}
            name="birthday_start"
            max="9999-12-31"
          />
        </div>
        ~
        <div className="col-sm-3">
          <input
            type="date"
            className="form-control form-control-sm"
            placeholderText="___/__/__"
            value={
              advanceSearch.birthday_end
                ? new Date(advanceSearch.birthday_end)
                  .toISOString()
                  .split("T")[0]
                : ""
            }
            onChange={e => {
              const selectedDate = e.target.value;
              setAdvanceSearch({
                ...advanceSearch,
                birthday_end: selectedDate,
              });
            }}
            name="birthday_end"
            max="9999-12-31"
          />
        </div>
      </div>

      <div className="form-group mb-2 row">
        <label className="col-sm-3 col-form-label text-sm-end">郵便番号:</label>
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control"
            name="zip_code"
            value={advanceSearch.zip_code}
            onChange={handleInput}
          />
        </div>
      </div>
      <div className="form-group mb-2 row">
        <label className="col-sm-3 col-form-label text-sm-end">
          電話番号1:
        </label>
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control"
            name="telePhone_1"
            value={advanceSearch.telePhone_1}
            onChange={handleInput}
          />
        </div>
      </div>
      <div className="form-group mb-2 row">
        <label className="col-sm-3 col-form-label text-sm-end">
          電話番号2:
        </label>
        <div className="col-sm-3">
          <input
            type="text"
            className="form-control"
            name="telePhone_2"
            value={advanceSearch.telePhone_2}
            onChange={handleInput}
          />
        </div>
      </div>
      <div className="form-group mb-2 row">
        <strong className="col-sm-3 col-form-label text-sm-center">
          クラス
        </strong>
      </div>
      <div className="row mt-1">
        <div style={{ textAlign: "right" }} className="col-md-3">
          <Form.Label>開校年:</Form.Label>
        </div>
        <Form.Group
          className="col-md-2"
          controlId="opening_year"
        >
          <Form.Select size="sm"
            name="opening_year"
            onChange={(e) => changeDataInput({ opening_year: e.target.value })}
            className="shadow-sm"
            value={dataInput.opening_year}
          >
            <option value="">開校年</option>
            {Array.from(new Set(allClasses.map((st_class) => st_class.opening_year))).map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}{" "}
          </Form.Select>
        </Form.Group>
        <div className="col-md-2 d-flex justify-content-end">
          <Form.Label>時期:</Form.Label>
        </div>
        <Form.Group
          className="col-md-3"
          controlId="family_member_id"
        >
          <Form.Select size="sm"
            name="opening_season"
            onChange={(e) => changeDataInput({ opening_season: e.target.value })}
            className="shadow-sm"
            value={dataInput.opening_season}
          >
            <option value="">時期</option>
            {Array.from(new Set(allClasses.filter(st_class => st_class.opening_year == dataInput.opening_year).map((st_class) => st_class?.opening_season))).map((season) => (
              <option key={season} value={season}>
                {seasons[season]}
              </option>
            ))}{" "}
          </Form.Select>
        </Form.Group>
      </div>
      <div className="row mt-1">
        <div style={{ textAlign: "right" }} className="col-md-3">
          <Form.Label>クラス名:</Form.Label>
        </div>
        <Form.Group
          className="col-md-4"
          controlId="family_member_id"
        >
          <Form.Select size="sm"
            name="short_term_class_id"
            onChange={(e) => changeDataInput({ short_term_class_id: e.target.value })}
            className="shadow-sm"
            value={dataInput.short_term_class_id}
          // Set the selected value to the state value
          >
            <option value="">クラスの選択</option>
            {classes.filter(c => (c.opening_season == dataInput.opening_season && c.opening_year == dataInput.opening_year)).map((st_class) => (
              <option
                key={st_class.id}
                value={st_class.id}
              >
                {st_class.class_name}
              </option>
            ))}{" "}
          </Form.Select>
        </Form.Group>
      </div>
      <div className="row mt-1">
        <div style={{ textAlign: "right" }} className="col-md-3">
          <Form.Label >
            短期コース:
          </Form.Label>
        </div>
        <Form.Group className="mb-3 main-body-inner__id justify-content-between col-md-6">
          <Form.Select
            size="sm"
            className="shadow-sm w-50"
            name={"short_term_course_id"}
            value={dataInput.short_term_course_id}
            onChange={(e) => changeDataInput({ short_term_course_id: e.target.value })}
          >
            <>
              <option value="">コースの選択</option>
              {courses.filter(c => c.short_term_class_id == dataInput.short_term_class_id).map(item => (
                <option value={item.id}>{item.course_name}</option>
              ))}
            </>
          </Form.Select>
        </Form.Group>
      </div>
      <hr />

      <div className="form-group mb-2 row">
        <label className="col-sm-3 col-form-label text-sm-center">
          送迎バス
        </label>
      </div>
      <div className="form-group mb-2 row">
        <div className="col-sm-3 col-form-label text-sm-end ">
          <input
            className="form-check-input me-2"
            type="checkbox"
            name="bus_user"
            onChange={handleCheckBox}
            checked={advanceSearch.bus_user}
          />
          バス利用者
        </div>
        <label className="col-sm-2 col-form-label text-sm-center">
          バスコース:
        </label>
        <div className="col-sm-5 justify-content-sm-start">
          {/* dropdown select option */}
          <select
            className="form-control"
            name="bus_course_number"
            onChange={handleInput}
            value={advanceSearch.bus_course_number}
          >
            <option value="">すべて</option>
            {buses.map((bus, i) => (
              <option key={i} value={bus.id}>
                {bus.bus_course_number} ({bus.stop_name})
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

export default ShortTermAdvanceSearch;
